@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap');
.headText{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.row1{
    background: rgba(31, 32, 79, 0.45);
    color: #FFFFFF;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 25px;
    padding: 20px;
    margin-top: 20px;
    user-select: none;
}

.row2{
    color: #FFFFFF;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 25px;
    padding: 20px;
    margin-top: 20px;
    user-select: none;
}

.header{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 20px;
}

.highlight{
    font-weight: 600;
    font-size: 17px;
}