@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&display=swap');

.bg{
    padding-bottom: 50px;
}
.row1{
    padding: 40px 60px 0px 90px;
}

.icons{
    text-align: center;
}
.icon{
    font-size: 35px;
    margin-right: 35px;
    margin-bottom: 0px;
    color: #Ffffff;
}

.linkbox{
    margin-bottom: 8px;
}


.links{
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 29px;
    color: #ffffff;
    text-decoration: none;
}

.links:hover{
    text-decoration: none;
    color: #ffffff;
}

.line{
    margin-top: 45px;
    margin-left: 80px;
    border: 3px solid #ffffff;
    width: 95%;
}

.brand{
    width: 83%;
}

.copyright{
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 29px;
    color: #ffffff;
    padding-left: 85px;
    padding-top: 5px;
}

@media(max-width: 576px){
    .row1{
        padding: 30px 40px 0px 40px;
    }

    .links{
        font-size: 12px;
        line-height: 15px;
    }

    .icons{
        text-align: left;
        margin-top: 10px;
    }

    .icon{
        font-size: 25px;
        margin-right: 17px;
        margin-bottom: 15px;
    }

    .line{
        margin-top: 22px;
        margin-left: 30px;
        border: 2px solid #ffffff;
        width: 100%;
    }

    .copyright{
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
        padding-left: 40px;
        padding-top: 5px;
    }
    .brand{
        width: 100%;
    }
}