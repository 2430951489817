@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700&display=swap');

.bg{
    margin-top: 60px;
    background-color: #1F143F;
}

.headText{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-size: 55px;
    line-height: 79px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 100px;
}

.myVideo{
    text-align: center;
}

iframe{
    margin:110px auto;
    width: 95%;
    aspect-ratio: 16/9;
}

@media(max-width: 576px){
    .headText{
        font-size: 32px;
        padding-top: 18px;
    }

    iframe{
        margin-top: 30px;
        margin-bottom: 50px;
    }
}