@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&display=swap');

.logo{
    border-radius: 45px;
    filter: drop-shadow(6px 6px 6px rgba(0, 0, 0, 0.75));
    width: 50px;
}

.brand{
    width: 230px;
}


.drop{
    margin-left: 20px;
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF !important;
    text-shadow: 6px 6px 6px rgba(0, 0, 0, 0.75);
}

.dropItem{
    text-shadow: none;
}

.navItems{
    margin-left: 20px;
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF !important;
    text-shadow: 6px 6px 6px rgba(0, 0, 0, 0.75);
}

.icon{
    font-size: 35px;
    margin-right: 35px;
    margin-bottom: 0px;
    color: #Ffffff;
}

@media(max-width: 576px){
    .logo{
        width: 48px;
    }
    .brand{
        width: 200px;
    }
}