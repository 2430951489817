@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700&display=swap');
.myCarousel{
    width: 85%;
    margin:auto;
}

.headText{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
}

.carouselText{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-align: left;
    margin-top: 100px;
}

.pic{
    width: 100%;
    margin-top: 65px;
}

.btn{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: #FFFFFF;
    color: #1F143F;
    padding: 5px 15px;
    border-radius: 10px;
    float: left;
}

.highlight{
    font-weight: 600;
}

@media(max-width: 576px){
    .headText{
        font-size: 18px;
        line-height: 20px;
        font-weight: 400;
    }

    .pic{
        margin-top: 10px;
    }

    .carouselText{
        font-size: 14px;
        line-height: 17px;
        margin-top: 10px;
    }

    .btn{
        font-weight: 500;
        font-size: 12px;
        padding: 2px 7px;
        border-radius: 7px;
    }

}