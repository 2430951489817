html,body{
  overflow-x: hidden;
  overflow-y: auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel-indicators button {
  width: 10px !important;
  height: 13px !important;
  border-radius: 60%;
  border: 1px solid #FFFFFF;
  background-color: transparent;
}

.carousel-indicators button:not(:first-child) {
  margin-left: 15px;
}

.carousel-indicators .active {
  background-color: #ffffff;
}

.carousel-caption{
  top: 0;
  bottom: auto;
}

@media(max-width: 576px){
  .carousel-indicators button {
    visibility: hidden;
  }

  .img{
    height: 460px;
  }
}