@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700&display=swap');

body{
    background-image: url("/public/Background-new.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
}

.headText{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 20px;
    margin: auto;
    width: 55%;
}

.icons{
    margin-top: 280px;
    margin-bottom: 280px;
}


.icon{
    color: #FFFFFF;
    font-size: 42px;
    margin: 20px auto;
    filter: drop-shadow(6px 6px 6px rgba(0, 0, 0, 0.75));
}

@media(max-width: 576px){
    .headText{
        font-size: 25px;
        line-height: 30px;
        width: 75%;
    }

    .icons{
        display: flex;
        margin: auto;
        justify-content: center;
    }

    .icon{
        font-size: 1px;
        margin-left: 20px;
        margin-right: 20px;
        visibility: hidden;
    }
}